<template>
   
    <div class="wallet">
         <div class="recharge_header">
              <van-nav-bar    left-arrow  @click-left="onClickLeft">
                <template #title>
                    <div class="title">Wallet</div>
                </template>
                 
            </van-nav-bar>

          </div>
          <div style="padding:20px;">
           <div style="display:flex;padding-top:100px;">
              <div style="width:45px;line-height:0px;margin-right:5px;"><img src="../assets/cryimg/head.b8e5d31e.png" style="width:100%"/></div>
              <div style="font-size:0.4rem;display:flex;flex-direction: column;">
                  <div>{{userInfo.user_name}}</div>
                  <div style="font-size:0.5rem">VIP{{userInfo.vip_level}}</div>
              </div>
           </div>
           <div style="background:#ffffff;border-radius:10px;padding:10px;margin-top:20px;">
               <div style="font-size:0.4rem;">My assets(USDT)</div>
               <div style="margin-top:20px;"><span style="font-weight:bold">{{parseFloat(userInfo.balance)+parseFloat(userInfo.lock_balance)}}</span><span style="font-size:0.4rem;"> USDT</span></div>
               <div style="display:flex;margin-top:15px;">
                  <a class="fn" @click="goRecharge">Recharge</a><a class="fn" @click="goWithdraw">Withdraw</a><a class="fn" @click="goTransfer">Transfer</a>
               </div>
               <div style="display:flex;margin-top:20px;justify-content: space-between;">
                  <div style="font-size: 0.4rem;display: flex;align-items: center;"><img src="../assets/cryimg/icon-usdt.png" style="width:25px;"/> USDT</div>
                  <div style="font-size:0.4rem;">{{parseInt(userInfo.balance)+parseInt(userInfo.lock_balance)}}</div>
               </div>
           </div>
            <div style="background:#ffffff;border-radius:10px;padding:10px;margin-top:10px;">
               <div style="font-size:0.4rem;">My assets(USD)</div>
               <div style="margin-top:20px;"><span style="font-weight:bold">0.00</span><span style="font-size:0.4rem;"> USDT</span></div>
               <div style="display:flex;margin-top:15px;">
                  <a class="fn">Withdraw</a><a class="fn">Transfer</a> 
               </div>
               <div style="display:flex;margin-top:20px;justify-content: space-between;">
                  <div style="font-size: 0.4rem;display: flex;align-items: center;"><img src="../assets/cryimg/icon-usd.png" style="width:25px;"/> USD</div>
                  <div style="font-size:0.4rem;">0.00</div>
               </div>
           </div>
           </div>
    </div>
</template>
<style scoped>
 .wallet{
    padding:0px 0px 20px;
    background: #ecf4ff;
 }
 .fn{
   padding:8px 12px;
   border: 1px solid #b7b8b8;
    margin: 5px;
    font-size:0.4rem;
    border-radius:5px;
 }
</style>
<script>
import {fetch,post} from '@/util/api'
import {getUserInfo} from '@/util/api'
export default {
   data(){
      return{
         userInfo:{}
      }
   },
   methods:{
      goRecharge:function(){
            this.$router.push({
                  path: "/Recharge" 
            });
      },
      goWithdraw:function(){
         this.$router.push({
              path: "/withdraw" 
        });
      },
      goTransfer:function(){
            this.$router.push({
                        path: "/tranfer" 
                     });
      },
       init:async function(){
          this.userInfo=await getUserInfo();
       },
         onClickLeft:function(){
            this.$router.back(-1)
        },
   },
   activated(){
        this.init();
         
    },
   mounted(){
      console.log("mounted.........");
      this.init();
   }


   
}
</script>
 

