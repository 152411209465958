<template>
    <div class="recharge">
          <div class="recharge_header">
              <van-nav-bar    left-arrow  @click-left="onClickLeft">
                <template #title>
                    <div class="title">Recharge</div>
                </template>
                <template #right>
                    <div class="submit" @click="goRecords" >
                         <img src="../assets/cryimg/record.png" style="width:20px;"/>
                        
                    </div>
                </template>
            </van-nav-bar>

          </div>
          <div class="recharge_content">
              <div class="recharge_amout">
                   <div style="margin-bottom:0.5rem;font-size:0.4rem;margin-top:1rem;">NetWork:</div>
                   <div style="display:flex">
                    <img src="../assets/cryimg/trc20.png" style="width:41px;margin-right:10px;" />
                     <select class="input-select">
                        <option>USDT-TRC20</option>
                     </select>
                   </div>
                   <div style="border-bottom: 0.05rem solid #efefef;margin-top:30px;"></div>
                   <div style="margin-bottom:0.5rem;font-size:0.4rem;margin-top:1rem;">Amount:</div>
                    <div >
                         <input  class="input-text" v-model="money" placeholder="Minimum recharge 50USDT"/>
                    </div>
                     <div style="border-bottom: 0.05rem solid #efefef;margin-top:30px;margin-bottom:20px;"></div>
              </div>
             
              <div class="pay">
                  <a class="btnactive" @click="doRecharge"  >Recharge</a>
                 
              </div>
              <div style="margin:0px 25px;font-size:0.4rem;line-height:0.6rem;">
                 <p>
Use wallet or exchange transfer recharge address.
After the top-up is completed, the amount will be automatically added to your account. 

                  </p>
              </div>
          </div>
       <f-dialog ></f-dialog>
            <van-popup v-model="show" position="bottom" :overlay="true" :style="{ height: '30%' }" >
          <div style="padding: 20px;
    font-size: 0.4rem;
    text-align: center;">
            <div>
                <van-icon name="cross" style="float:left;font-weight:bold;font-size:0.5rem;"/>
                KEY
            </div>
            <div style="margin-top:30px">
                <input class="input-text" type="password" v-model="key" placeholder="please enter KEY"/>
            </div>
            <div style="display:flex;margin-top:20px;"><a class="btnblue" @click="docharge">Submit</a></div>
          </div>
            </van-popup>
    </div>
</template>
<style>
.btnblue {
        background: #507ae6;
    color: #fff;
    width: 100%;
    display: block;
    padding: 9px;
    border-radius:5px;
}
.input-text, .input-select {
    width: 100%;
    padding: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 6px;
    color: #0a0a0a;
    background: #f6f6f6;
    font-size: 14px;
    caret-color: #3c6fef;
    outline: none;
    -webkit-transition: all .3s;
    transition: all .3s;
}
::placeholder {
  color: gray;
  opacity: 0.4; /* Firefox */
}

::-ms-input-placeholder { /* Edge 12-18 */
  color: gray;
}
</style>
<style scoped>
.btn-disabled {
    background: #999;
}
.btnactive{
      background: #588bf8;
    opacity: 0.97;
    cursor:pointer;
}
.input-text, .input-select {
    width: 100%;
    padding: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 6px;
    color: #0a0a0a;
    background: #f6f6f6;
    font-size: 14px;
    caret-color: #3c6fef;
    outline: none;
    -webkit-transition: all .3s;
    transition: all .3s;
}
button, input, select, textarea{
        background-color: transparent;
    resize: none;
    border: none;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.input-select{
    height:41px;
}
.pay{
    display:flex;
    align-items: center;
    justify-content: center;
    margin-top:20px;
}
.pay a{
    width:85%;
    height:50px;
    font-size:20px;
    color:#ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1.4rem;
}
.recharge_amout{
    display:flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin:0px 25px;
}
.recharge_item a{
height:66px;
margin:0px 5px 5px 5px;
background: #ffffff;
border-radius: 10px;
display:flex;
align-items: center;
justify-content: center;
color:#333333;
font-size: 14px;
}
.define_money{
    background: #ffffff;
    border-radius: 10px;
    margin:0px 12px;
    display: flex;
    height:30px;
    align-items: stretch; 
    padding:10px;

}
.define_money input{
    border:1px solid transparent;
    flex-grow: 1;
    font-size: 15px;
    
}
.recharge_item{
    flex-basis: 33%;
    margin-bottom: 5px;
    
}
.recharge_content{
  margin-top:10px;
}
.recharge_header .title{
    font-weight: bold;
    color:#333333;
}
.recharge_header{
    height:50px;
}
  .recharge{
      background: #FFFFFF;
      width:100%;
      font-size:12px;
  }
  .recharge_amout .active>a{
      border:1px solid #F9034F;
      color :#F9034F;
  }
</style>
<script>
import fdialog from './dialog' 
 import {fetch,post} from '@/util/api'
 import {getBackData,getUserInfo} from '@/util/api'
 
export default {
   components:{fDialog:fdialog},
    data(){
        return{
            money:'',
            amountList:[],
            preSelectIndex:null,
            rechargeInfo:null,
            src:null,
            show:false,
            key:""
        }
    },
    methods:{
             closepop:function(){
             this.show=false;
        },
        doRecharge:function(){
             ///发起重置请求，检查金额，要求最低 50 usdt
           if(this.money<50){
              this.$toast("The minimum recharge amount cannot be less than 50");
              return;
           }
              this.show=true;
        },
        goRecords:function(){
this.$router.push({
                    path: "/rrecord" 
                });
        },
        docharge:function(){
            if(!this.key){
                 this.$toast("please enter KEY");
                return;
            }
           this.$toast.loading({
                        duration:0,
                        message: 'submit...',
                        forbidClick: true,
                        loadingType: 'spinner' 
                        });
           post("api/wallet/createOrder",{money:this.money,key:this.key}).then(res=>{
               if(res.code==1){
                    this.$router.push({path:"/rechargeview",query:{id:res.data.transaction_id}});
               }else{
                this.$toast(res.code_dec);
               }
                this.$toast.clear();
           })
        },
        onClickLeft:function(){
            this.$router.back(-1)
        },
        getRechargeInfo:function(){
           post("/api/recharge/getRechargeType",{}).then(res=>{
              if(res.code==1){
                  this.rechargeInfo=res.data;
              }
           })
        },
        hasSelect:function(index){
            if(this.preSelectIndex!=null){
                 this.amountList[this.preSelectIndex].select=false;
            }
           this.amountList[index].select=true;
           this.preSelectIndex=index;
        },
        getRechargeAmounts:function(){
              post("/api/recharge/getRechargeAmounts",{}).then(res=>{
                  if(res.code==1){
                      var temp=[];
                      if(res.data&&res.data.length>0){
                          for(var i=0;i<res.data.length;i++){
                            this.amountList.push(Object.assign({},res.data[i],{select:false}))
                          }
                      
                      } 
                  }
                    this.$toast.clear();
              }).catch(err=>{
                    this.$toast.clear();
              })
        },
        createOrder:function(){
            if(this.preSelectIndex==null&&!this.money){
                this.$toast("Please enter the amount to recharge");
                return;
            }
            if(!this.rechargeInfo){
                this.$toast("Payment channel not obtained");
                return;
            }
            if(this.preSelectIndex!=null){
                this.money=this.amountList[this.preSelectIndex].amount;
            }
            var temp={recharge_id:this.rechargeInfo.id,money:this.money,name:''};
            this.$toast.loading({
            duration:0,
            message: 'loading...',
            forbidClick: true,
            loadingType: 'spinner' 
            });
            post("/api/recharge/newRechargeOrder",temp).then(res=>{
                 this.$toast.clear();
                if(res.code_dec=="success"){
                    this.$toast(res.code_dec);
                    console.log(this.src);
                    var self=this;
                    setTimeout(()=>{
                        console.log("延迟执行");
                      if(self.src){
                            
                            self.$router.push({
                                    path: "/"+self.src,
                                });
                        }
                    },1000)
                    
                }else{
                    this.$toast(res.code_dec) 
                }
                
            }).catch(err=>{
                 this.$toast.clear();
            })
        },

       init:async function(){
            this.$toast.loading({
            duration:0,
            message: 'loading...',
            forbidClick: true,
            loadingType: 'spinner' 
            });
            var userInfo=await getUserInfo();
            console.log("recharge init user",userInfo);
            if(userInfo==null){
                this.$router.push({
                    path: "/login",
                    query:{src:"recharge"} 
                });
                this.$toast.clear();
                return;
            }else{
                 this.$toast.clear();
            }
          

       }
    },
    activated(){
        this.amountList=[];
     this.init();
      this.$store.dispatch("setShowNav",false);
     this.src=this.$route.query.src;
     console.log("this.src"+this.src);
     this.show=false;
    },
    created(){
       
    },
    mounted(){

    }
}
</script>

