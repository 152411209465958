<template>
    <div class="recharge">
          <div class="recharge_header">
              <van-nav-bar  v-if="!showBack"    @click-left="onClickLeft">
                <template #title>
                    <div class="title">Investment</div>
                </template> 
            </van-nav-bar>

               <van-nav-bar v-if="showBack"   left-arrow  @click-left="onClickLeft">
                <template #title>
                    <div class="title">Investment</div>
                </template>
                <template #right>
                    <div class="submit" @click="goRecords" >
                        Records
                        
                    </div>
                </template>
            </van-nav-bar>

          </div>
          <div class="recharge_content">
                   
                  <div class="muser_info">
                     <div class="muser_card">
                        <div style="display:flex;justify-content: space-between;color: #ffffff;font-size: 0.4rem;">
                            <div >Hi,{{userInfo.user_name}}</div>
                            <div><span v-if="userInfo.vip_level>0">VIP{{userInfo.vip_level}}</span><span v-if="userInfo.vip_level==0">Regular user</span></div>
                        </div>
                        <div style="padding:20px;flex-direction: row;justify-content: center;display: flex; align-items: center;color: #ffffff;font-size: 0.35rem;justify-content:space-between">
                           <div>
                            <div>Available balance</div>
                            <div style="font-size:0.45rem;">{{userInfo.balance}} <span>USDT</span></div>
                           </div>
                            <div>
                            <div>Locked balance</div>
                            <div style="font-size:0.45rem;">{{userInfo.lock_balance}} <span>USDT</span></div>
                           </div>
                        </div>
                        <div style="display:flex;justify-content: space-between;color:#ffffff">
                            <div>
                                <div >Daily receive limit</div>
                                <div>{{vipInfo.daily}} USDT</div>
                            </div>
                            <div>
                                   <div>Monthly receive limit</div>
                                <div>{{vipInfo.monthly}} USDT</div>
                            </div>
                        </div>

                     </div>

                  </div>
                  <div class="invest">
                      <div style="display:flex;align-items: center;font-weight:bold;font-size:0.4rem;"><img src="../assets/cryimg/icon-usdt.png" style="width:30px;margin-right:5px;"/>USDT</div>
                      <div style="background:#ffffff;border-radius:5px;padding:10px;margin-top:10px;">
                         <div style="display:flex;justify-content: space-between;">
                            <div>
                               <div style="font-size:0.5rem;font-weight:bold;color:rgb(233, 58, 61)" v-if="selectedItem!=null&&selectedItem.rate">{{parseInt(selectedItem.rate*100)+'%'}}</div>
                               <div style="font-size:0.3rem;color:#999999">Reference annualized income</div>
                            </div>
                            <div>
                                <div style="font-size:0.5rem;font-weight:bold">{{selectedItem.time}} Day</div>
                                <div style="font-size:0.3rem;color:#999999">investment period</div>
                            </div>

                         </div>
                         <div class="list_invest">
                             <div class="list_invest_item" :class="{selected:item.selected}" v-for="(item,index) in productList" @click="hasSelect(index,item)" :key="index">{{item.time}}</div>
                         </div>
                         <div style="display:flex;justify-content: space-between;align-items:center">
                            <div style="font-size:0.4rem;font-weight:bold;flex-grow:1;flex-basis: 0;">
					         	Starting quantity：{{selectedItem.min_amount}}USDT						
                        </div>
                            <div style="flex-grow:1;flex-basis: 0;text-align:center">
                              <a style="background: #2354E6;color: #fff;padding:12px 20px;border-radius: 8px;" @click="buyServices">  Participate now</a>

                            </div>
                         </div>

                      </div>

                  </div>
                  <div style="margin-top:20px;font-size:0.4rem;">
	<span style="color: #009393;"> The platform's partner USDT exchanges are listed below<br>
			<br></span>

                  </div>
                  <div style="display:flex;    flex-wrap: wrap;">
                     <div class="partner_item">
                        <a><img src="../assets/cryimg/1.jpg"></a>
                     </div>
                       <div class="partner_item">
                        <a><img src="../assets/cryimg/2.jpg"></a>
                     </div>
                       <div class="partner_item">
                        <a><img src="../assets/cryimg/3.jpg"></a>
                     </div>
                       <div class="partner_item">
                        <a><img src="../assets/cryimg/4.jpg"></a>
                     </div>
                       <div class="partner_item">
                        <a><img src="../assets/cryimg/5.jpg"></a>
                     </div>
                      <div class="partner_item">
                        <a><img src="../assets/cryimg/6.jpg"></a>
                     </div>
                      <div class="partner_item">
                        <a><img src="../assets/cryimg/7.jpg"></a>
                     </div>
                      <div class="partner_item">
                        <a><img src="../assets/cryimg/8.jpg"></a>
                     </div>
                                           <div class="partner_item">
                        <a><img src="../assets/cryimg/9.jpg"></a>
                     </div>

                  </div>
                  <div class="records">
                      <div class="mtitle">
                         <div class="square_icon"></div>
                         Platform records			
                      </div>
                      <div v-for="(item,index) in records" :key="index">
                          <div style="display:flex;padding:10px;font-size:0.4rem;">
                            <div style="width:30px;">
                                <img v-if="item.action=='withdraw'" src="../assets/cryimg/records2.png" style="width:100%;"/>
                                 <img v-if="item.action=='recharge'" src="../assets/cryimg/records1.png" style="width:100%;"/>
                            </div>
                            <div style="flex-grow:1;margin-left:20px;">
                                <div>{{item.username}}</div>
                                <div>{{item.action}}</div>
                            </div>
                            <div style="width:130px;">
                                <div><span style="font-style: normal;
    color: #FE9A2E;margin-right:5px;">{{item.amount}}</span>USDT</div>
                                <div>{{item.date}}</div>
                            </div>
                          </div>
                      </div>
                  </div>
              
          </div>
          <!-- <div  class="investment_footed">
                  <div class="input">
                      <div class="inputMoney">
                          <img src="@/assets/img/ic_money.png"/> 
                          <input v-model="money" placeholder="Please enter the storage amount"/>
                      </div>
                       <div class="income">
                          <div>{{profit}}</div>
                          <div>Estimated Income</div>
                       </div>

                  </div>
                  <div class="sureBtn">

                       <a @click="buy">Determine</a>
                  </div>

               </div> -->

                   <van-popup v-model="show" position="bottom" :overlay="true" :style="{ height: '30%' }" >
          <div style="padding: 20px;
    font-size: 0.4rem;
    text-align: center;">
            <div>
                <van-icon name="cross" @click="closepop" style="float:left;font-weight:bold;font-size:0.5rem;"/>
                KEY
            </div>
            <div style="margin-top:30px">
                <input class="input-text" type="password" v-model="code" placeholder="please enter KEY"/>
            </div>
            <div style="display:flex;margin-top:20px;"><a class="btnblue" @click="doBuy">Submit</a></div>
          </div>
            </van-popup>

    </div>
</template>

<script>
 import {fetch,post} from '@/util/api'
 import {getBackData,getUserInfo} from '@/util/api'
export default {
    data(){
        return{
            money:'',
            productList:[],
            prevIndex:null,
            profit:0,
            userInfo:{},
            vipInfo:{},
            selectedItem:{},
            show:false,
            code:"",
            showBack:false,
            records:[]
        }
    },
    methods:{
         closepop:function(){
             this.show=false;
        },
        buyServices:function(){
            this.show=true;
        }, 
        doBuy:function(){
            post("/api/active/buyFinancial",{code:this.code,activity_id:this.selectedItem.id}).then(res=>{
                if(res.code==1){
                   this.$toast(res.code_dec);
                    this.show=false;
                    this.refreshUser();
                }else{
                   this.$toast(res.code_dec);
                }
               
            })
        },
        refreshUser:async function(){
            this.userInfo =await getUserInfo();
        },
        goRecords:function(){
                this.$router.push({
                    path: "/record" 
                });
        },
        hasSelect:function(index,item){
            if(this.prevIndex!=null){
                this.productList[this.prevIndex].selected=false;
            }
            this.prevIndex=index;
            this.productList[index].selected=true;
            this.selectedItem=item;
        },
        onClickLeft:function(){
             this.$router.back(-1) 
        },
        init:async function(){
           this.$toast.loading({
            duration:0,
            message: 'loading...',
            forbidClick: true,
            loadingType: 'spinner' 
          });
          var userInfo=await getUserInfo();
          if(userInfo==null||!userInfo){
                this.$router.push({
                    path: "/login",
                    query:{src:"invite"} 
                });
                this.$toast.clear();
                return;
            }
             this.$toast.clear();
          this.userInfo=userInfo;
          this.getVipInfo();
           
          this.getProduct();
        },
        getVipInfo:function(){
           post("/api/vip/getUserVip",{}).then(res=>{
             this.vipInfo=res.data;
           });
        },
        getProduct:async function(){
            //获取理财产品列表
            this.productList=[];
            console.log("获取产品明细:");
            var res=await getBackData();
            var info=res.datas.actives;


             
                    info.sort((a,b)=>{
                        return parseInt(a.time) - parseInt(b.time)
                    });
                    for(var i=0;i<info.length;i++){
                        this.productList.push(Object.assign({},info[i],{selected:false}));
                    } 
                    console.log("productList:::",this.productList);
                    this.selectedItem=this.productList[0];
                    this.selectedItem.selected=true;
                    this.prevIndex=0;
                    this.$toast.clear();
             
        },
        buy:function(){
            if(this.prevIndex==null){
                this.$toast("Please select financial products");
                return;
            }
            if(!this.money){
                this.$toast("Please enter the financial management amount");
                return;
            }
            this.$toast.loading({
            duration:0,
            message: 'buying...',
            forbidClick: true,
            loadingType: 'spinner' 
            });
            post("/api/yuebao/payYuebao",{yuebaoid:this.productList[this.prevIndex].id,money:this.money}).then(res=>{
                 this.$toast.clear();
                if(res.code==1){
                    this.$toast(res.code_dec)
                }else{
                    this.$toast(res.code_dec)
                }
               
            }).catch(err=>{
                  this.$toast("Purchase failed")
                  this.$toast.clear();
            })
        },
        showRecord:function(count){
                const actions = ["recharge", "withdraw"];
                const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

                function getRandomName() {
                    let length = Math.floor(Math.random() * 5) + 4; // 用户名长度 4-8
                    let name = "";
                    for (let i = 0; i < length; i++) {
                        name += letters.charAt(Math.floor(Math.random() * letters.length));
                    }
                    return name;
                }

                function maskName(name) {
                    return name.substring(0, 2) + "*".repeat(name.length - 2);
                }

                function getRandomAmount() {
                    return (Math.random() * (30000 - 5000) + 5000).toFixed(2); // 5000 - 30000 之间
                }

                function formatDate(date) {
                    let y = date.getFullYear();
                    let m = String(date.getMonth() + 1).padStart(2, "0");
                    let d = String(date.getDate()).padStart(2, "0");
                    return `${y}-${m}-${d}`;
                }

                function getRandomTime() {
                    let now = new Date();
                    let todayStart = new Date(now.getFullYear(), now.getMonth(), now.getDate()).getTime(); // 今天 00:00:00
                    let yesterdayStart = todayStart - 24 * 60 * 60 * 1000; // 昨天 00:00:00

                    let isYesterday = Math.random() < 0.3; // 30% 数据来自昨天
                    if (isYesterday) {
                        let hour = Math.floor(Math.random() * 24); // 随机选择小时（0 - 23）
                        let decayFactor = 1 - hour / 24; // 越晚概率越低
                        if (Math.random() > decayFactor) {
                            return getRandomTime(); // 重新生成，减少晚上数据
                        }
                        let randomTime = yesterdayStart + hour * 60 * 60 * 1000 + Math.random() * (60 * 60 * 1000);
                        return new Date(randomTime);
                    } else {
                        let randomTime = todayStart + Math.random() * (24 * 60 * 60 * 1000);
                        return new Date(randomTime);
                    }
                }

                let records = [];
                for (let i = 0; i < count; i++) {
                    let username = getRandomName();
                    let time = getRandomTime();
                    records.push({
                        username: maskName(username),
                        action: actions[Math.floor(Math.random() * actions.length)],
                        amount: parseInt(getRandomAmount()),
                        date: formatDate(time), // 显示 YYYY-MM-DD
                        timestamp: time.getTime()
                    });
                }

                // 按时间降序排列，最新的在前
                records.sort((a, b) => b.timestamp - a.timestamp);

                return records;
        }
    },
      activated(){
          this.money='',
          this.prevIndex=null;
          var records=this.showRecord(50);
          this.records= records;
    
     if(this.$route.query&&this.$route.query.back&&this.$route.query.back=="no"){
             this.showBack=false;
             this.$store.dispatch("setShowNav",true);
     }else{
         this.showBack=true;
         this.$store.dispatch("setShowNav",false);
     }
  },
    watch:{
            money(val,okaval){
                if(this.prevIndex!=null){
                    var product=this.productList[this.prevIndex];
                    var value=((val*product.lilv)*product.time).toFixed(2);
                    console.log("value",value);
                    this.profit=parseInt(val)+parseFloat(value);
                }
            }
    },
    mounted(){
        this.init();
     
    }
}
</script>
<style scoped>

.list_invest .selected {
    background: #b9bfed;
    color: #0734b9;
}
.mtitle{
    display:flex;
    padding:15px;
}
.square_icon{
    background: linear-gradient(270deg, #f7cb6b, #fba980);
    border-radius: 5px;
    height: 18px;
    margin-right: 10px;
    width: 7px;
}
.records{
        margin: 20px auto;
    border-radius: 12px;
    background-color: #7fbacb;
    padding: 0 2% 5px;
    color: #fff;
}
.partner_item img{
    width:100%;
}
.partner_item{
    border: 0.5px solid #E6E9EB;
    box-sizing: border-box;
    margin: 10px;
}
@media screen and (max-width: 750px){
.partner_item {
    border: 0.5px solid #E6E9EB;
    box-sizing: border-box;
    margin: 2px; 
    width:32%;
}
}

.list_invest{
    background: #efefef;
    margin: 5px 0px 5px 0px;
    padding: 10px;
    border-radius: 10px;
    display:flex;
    flex-wrap: wrap;
}
.list_invest_item{ 
       background: #fff;
    margin: 7px;
    padding: 3px 18px 3px 18px;
    border-radius: 5px;
    
}
.invest{
    background: #7fbacb;
    border-radius: 5px;
    padding: 12px;
    margin-top: 10px;
}
.muser_info{
    background: url("../assets/cryimg/card.8c0955e2.png") 100% no-repeat;
    background-size: 100% 100%;
}
.muser_card{
    padding:15px;
}
.days{
    color:#333333;
    font-weight:bold;
}
.input{
    display:flex;
    flex-direction: column;
    margin-right:15px;
}
.input>div{
    align-items: center; 
}
.inputMoney,.income{
    flex-grow: 1;
    display:flex;
}
.income{
    justify-content: space-between;
}
.inputMoney>input{
    border:1px solid transparent;
    flex-grow: 1;
    padding-left:10px;
    font-size:14px;
}
.sureBtn>a{
    width:114px;
    height:38px;
    background:#F9034F;
    color:#ffffff;
    display: block;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size:16px;
    border-radius: 5px;
}
.inputMoney>img{
    width:10px;
    height:16px;
}
.sureBtn{
    width:114px;
    display: flex;
    align-items: center;
    justify-content: center;
}
.inputMoney{
    display:flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    border-bottom: 1px solid #E5E5E5; 
}
.investment_footed>div:nth-child(1){
    flex-grow: 1;
}
.investment_footed{
    height:2rem;
    position: fixed;
    bottom: 0px;
    background:#ffffff;
    right:0px;
    left:0px;
    z-index:1000;
    padding:10px 20px;
    display:flex; 
    flex-direction: row;
}
.daily_info>div:nth-child(1){
    border-bottom: 1px solid #E5E5E5;
}
.daily_info>div{
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}
.income>div:nth-child(1){
    color:#F90E57;
    font-size:0.45rem;
}
.daily_income>div{
    display:flex;
    align-items: center;
    justify-content: center;
}
.daily_income>div:nth-child(2){
    color:#999999;
    font-size:0.25rem;
}
.daily_income>div:nth-child(1){
    color:#F9034F;
    font-weight: bold;
    font-size: 0.45rem;
}
.product>div:nth-child(1){
    color:#333333;
    font-size:0.35rem;
    
}
.product>div:nth-child(2){
    color:#666666;
    font-size:0.35rem;
    
}
.daily_income{
    width:100px;
    height:1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}
.invedt_item>div{
    flex-grow: 1;
}
.invedt_item{
    display:flex;
    flex-direction: column;
    background: #ffffff;
    border-radius:10px;
    padding:10px;
    margin-bottom: 10px;
}
.product{
    display:flex;
    flex-direction: row;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom:1px solid #E5E5E5;
    margin-bottom: 10px;
    
}
.invedt_item{
    margin:0px 12px 12px;
}
.product_info{
    display: flex;
    flex-direction: row;
}
.daily_info{
    display:flex;
    flex-direction: column;
    flex-grow: 1;
}
.daily_info>div{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}
</style>
<style scoped>
.input-text, .input-select {
    width: 100%;
    padding: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 6px;
    color: #0a0a0a;
    background: #f6f6f6;
    font-size: 14px;
    caret-color: #3c6fef;
    outline: none;
    -webkit-transition: all .3s;
    transition: all .3s;
    border:none;
}
::placeholder {
  color: gray;
  opacity: 0.4; /* Firefox */
}

::-ms-input-placeholder { /* Edge 12-18 */
  color: gray;
}
.btnblue {
        background: #507ae6;
    color: #fff;
    width: 100%;
    display: block;
    padding: 9px;
    border-radius:5px;
}
.expect_money{
    margin-top:10px;
    padding:0px 14px;
}
.pay{
    display:flex;
    align-items: center;
    justify-content: center;
    margin-top:20px;
}
.pay a{
    width:224px;
    height:50px;
    font-size:20px;
    color:#ffffff;
    background: #F9034F;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
}
.recharge_amout{
    display:flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin:0px 10px;
}
.recharge_item a>div{
    display: flex;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
}
.recharge_item a>div:nth-child(1){
    color:#666666;
}
.recharge_item a>div:nth-child(2){
    color:#333333;
    font-size: 18px;
}
.recharge_item a>div:nth-child(3){
    color:#999999;
}
.recharge_item a{
height:100px;
margin:0px 5px 5px 5px;
background: #ffffff;
border-radius: 10px;
display:flex;
align-items: center;
justify-content: center;
color:#333333;
font-size: 14px;
flex-direction: column;
}
.define_money{
    background: #ffffff;
    border-radius: 10px;
    margin:0px 12px;
    display: flex;
    height:30px;
    align-items: stretch; 
    padding:10px;

}
.define_money input{
    border:1px solid transparent;
    flex-grow: 1;
    font-size: 15px;
    
}
.recharge_item{
    flex-basis: 33%;
    margin-bottom: 5px;
    
}
.recharge_content{
  margin-top:10px;
  padding:20px;
}
.recharge_header .title{
    font-weight: bold;
    color:#333333;
}
.recharge_header{
    height:50px;
}
  .recharge{
      background: #F4F5F7;
      height:100%;
      width:100%;
      font-size:12px;
  }
  .active>a>div{
      color:#F9034F !important;
  }
  .active>a{
      border:1px solid #F9034F;
      color:#F9034F !important;
  }
  .submit{
      color:#F9034F;
  }

</style>
