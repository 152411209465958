<template>
    <div class="business_hall">
         <div class="business_hall_header">
            <van-nav-bar    left-arrow  @click-left="onClickLeft">
                <template #title>
                    <div class="title">Modify email</div>
                </template>
                
            </van-nav-bar>
        </div>
        <div class="buywrap">
              <van-cell-group>
                <van-field label-width="120px" type="text"  v-model="email" label="EMAIL" placeholder="Please enter your Email" />
                <van-field label-width="120px" type="text" v-model="email2" label="EMAIL" placeholder="Please confirm your email again" />
                <van-field label-width="120px" type="password" v-model="key" label="KEY" placeholder="Please enter KEY" />
             </van-cell-group>
             <div style="padding:10px;margin-top:20px;display:flex"><a @click="submitUpdate" style="background: rgb(0, 114, 255);
    flex-grow: 1;
    padding: 10px;
    text-align: center;
    color: #ffffff;
    border-radius: 24px;
    font-size:0.4rem;
}">Confirm</a></div>

        </div>
    </div>
</template>
<script>
import {fetch,post} from '@/util/api'
export default {
    data(){
        return {
             email:"",
             email2:"",
             key:""
        }
    },
    methods:{
        onClickLeft:function(){
              this.$router.back(-1)
        },
        submitUpdate:function(){
            if(!this.email){
                this.$toast("Please enter your email address");
                 return;
            }
            if(!this.email2){

                this.$toast("Please enter your email address again");
                 return;
            }
            if(!this.key){

                this.$toast("Please enter your key");
                 return;
            }
            if(this.email!=this.email2){
                 this.$toast("The two email addresses you entered do not match");
                 return;
            }
           this.$toast.loading({
            duration:0,
            message: 'submiting...',
            forbidClick: true,
            loadingType: 'spinner' 
            });
            post("api/user/updateEmail",{email:this.email,key:this.key}).then(res=>{
                this.$toast.clear();
                this.$toast(res.code_dec);
                 
                 
            })
        }
    },
    activated(){
        this.$store.dispatch("setShowNav",false);
    }
}
</script>    
<style>
.buywrap input {
    width: 100%;
    padding: 5px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 6px;
    color: #0a0a0a;
    background: #f6f6f6;
    font-size: 14px;
    caret-color: #3c6fef;
    outline: none;
    -webkit-transition: all .3s;
    transition: all .3s;
}
</style>