<template>
    <div class="recharge">
          <div class="recharge_header">
              <van-nav-bar    left-arrow  @click-left="onClickLeft">
                <template #title>
                    <div class="title">TRANFER</div>
                </template>
                
            </van-nav-bar>

          </div>
          <div class="recharge_content">
              <div class="recharge_amout">
                   <div style="margin-bottom:0.5rem;font-size:0.4rem;margin-top:0.2rem;">
                    <img src="../assets/cryimg/icon-usdt.png" style="width:20px;"> USDT
                   </div>
                   <div style="border-bottom: 0.05rem solid #efefef;margin-bottom:5px;"></div>
                   <div style="margin-bottom:0.5rem;font-size:0.4rem;margin-top:0.5rem;display:flex">
                       
                      <div class="user_item user_item_active">User Name</div>
                   </div>
                    <div >
                         <input  class="input-text" v-model="username" placeholder="The recipient needs at least Vip1"/>
                    </div>
                     <div style="border-bottom: 0.05rem solid #efefef;margin-top:30px;margin-bottom:20px;"></div>

                   <div style="margin-bottom:0.5rem;font-size:0.4rem;margin-top:0.5rem;color:red">
                    VIP members can transfer assets without KEY 
                    </div>
                    
                   <div style="margin-top:30px;border-bottom: 0.05rem solid #efefef;"></div>
                   <div style="margin-bottom:0.5rem;font-size:0.4rem;margin-top:0.5rem;">Amount:</div>
                    <div >
                         <input  class="input-text" v-model="money" @blur="cacfee" placeholder="5-1000000"/>
                         <div style="display:flex;justify-content: space-between;margin-top:5px;">
                            <span style="color: #999;">Available balance</span>
                            <span style="color: #999;">{{balance.balance}}USDT</span>
                         </div>
                    </div>
                     <div style="border-bottom: 0.05rem solid #efefef;margin-top:30px;margin-bottom:20px;"></div>
                     <div style="font-size:0.4rem;line-height:0.6rem;display:flex">
                        <div style="width:80px;">Service charge:</div>
                        <div style="display:flex;justify-content: space-between;
    flex-grow: 1;">
                            <div style="flex-grow: 1;
    align-items: center;
    display: flex;
    justify-content: center;"><span v-if="fee>0">{{fee}}</span></div>
                            <div style="flex-grow:1;align-items: center;
    display: flex;
    justify-content: flex-end;">USDT</div>
                        </div>
                     </div>
                      <div style="border-bottom: 0.05rem solid #efefef;margin-top:30px;margin-bottom:20px;"></div>
              </div>
             
              <div class="footer">
                <div style="display:flex;justify-content: space-between;">
                  <div style="font-size:0.35rem;margin-left:10px;"><span style="font-weight:bold">{{real_money}}</span> USDT</div>
                  <div>
                    <a class="btn-disabled withdraw" @click="doTransfer" :class="classObject">TRANSFER</a>
                  </div>
                 </div>
              </div>
              
          </div>
       <f-dialog ></f-dialog>
              <van-popup v-model="show" position="bottom" :overlay="true" :style="{ height: '30%' }" >
          <div style="padding: 20px;
    font-size: 0.4rem;
    text-align: center;">
            <div>
                <van-icon name="cross" @click="closepop" style="float:left;font-weight:bold;font-size:0.5rem;"/>
                KEY
            </div>
            <div style="margin-top:30px">
                <input class="input-text" v-model="code" placeholder="please enter KEY"/>
            </div>
            <div style="display:flex;margin-top:20px;"><a class="btnblue" @click="goTransfer">Submit</a></div>
          </div>
            </van-popup>
    </div>
</template>
<style scoped>
.input-text, .input-select {
    width: 100%;
    padding: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 6px;
    color: #0a0a0a;
    background: #f6f6f6;
    font-size: 14px;
    caret-color: #3c6fef;
    outline: none;
    -webkit-transition: all .3s;
    transition: all .3s;
    border:none;
}
::placeholder {
  color: gray;
  opacity: 0.4; /* Firefox */
}

::-ms-input-placeholder { /* Edge 12-18 */
  color: gray;
}
.btnblue {
        background: #507ae6;
    color: #fff;
    width: 100%;
    display: block;
    padding: 9px;
    border-radius:5px;
}
.btnactive {
    background: #588bf8 !important;
    opacity: 0.97;
}
.user_item{
    margin:0px 0.1rem;
    border-bottom:0.1rem solid transparent;
    padding:0px 0.1rem;
}
.user_item_active{
    border-bottom:0.1rem solid #be0000 !important;
}
.withdraw{
        padding: 0.5rem 1rem;
    color: #ffffff;
    border-radius: 0.8rem;
    font-weight: bold;
    margin-right: 15px;
    font-size:0.35rem;
}
.footer{
    position:fixed;
    bottom:0px;
    width:100%;
    height:40px;
}
.btn-disabled {
    background: #999;
}
.input-text, .input-select {
    width: 100%;
    padding: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 6px;
    color: #0a0a0a;
    background: #f6f6f6;
    font-size: 14px;
    caret-color: #3c6fef;
    outline: none;
    -webkit-transition: all .3s;
    transition: all .3s;
}
button, input, select, textarea{
        background-color: transparent;
    resize: none;
    border: none;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.input-select{
    height:41px;
}
.pay{
    display:flex;
    align-items: center;
    justify-content: center;
    margin-top:20px;
}
.pay a{
    width:85%;
    height:50px;
    font-size:20px;
    color:#ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 1.4rem;
}
.recharge_amout{
    display:flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin:0px 25px;
}
.recharge_item a{
height:66px;
margin:0px 5px 5px 5px;
background: #ffffff;
border-radius: 10px;
display:flex;
align-items: center;
justify-content: center;
color:#333333;
font-size: 14px;
}
.define_money{
    background: #ffffff;
    border-radius: 10px;
    margin:0px 12px;
    display: flex;
    height:30px;
    align-items: stretch; 
    padding:10px;

}
.define_money input{
    border:1px solid transparent;
    flex-grow: 1;
    font-size: 15px;
    
}
.recharge_item{
    flex-basis: 33%;
    margin-bottom: 5px;
    
}
.recharge_content{
  margin-top:10px;
}
.recharge_header .title{
    font-weight: bold;
    color:#333333;
}
.recharge_header{
    height:50px;
}
  .recharge{
      background: #FFFFFF;
      width:100%;
      font-size:12px;
  }
  .recharge_amout .active>a{
      border:1px solid #F9034F;
      color :#F9034F;
  }
</style>
<script>
import fdialog from './dialog' 
 import {fetch,post} from '@/util/api'
 import {getBackData,getUserInfo} from '@/util/api'
 import { Dialog } from 'vant';
export default {
   components:{fDialog:fdialog},
    data(){
        return{
            money:'',
            amountList:[],
            preSelectIndex:null,
            rechargeInfo:null,
            src:null,
            balance:{},
            real_money:0,
            fee:0,
            showActive:false ,
            show:false,
            code:"",
            userInfo:{},
            username:""

        }
    },
     computed: {
        classObject() {
            return {
                "btnactive": this.showActive 
            };
        }
    },
    methods:{
        goTransfer:function(){
              this.$toast.loading({
                        duration:0,
                        message: 'loading...',
                        forbidClick: true,
                        loadingType: 'spinner' 
                        });
             post("api/wallet/transfer",{real_money:this.real_money,code:this.code,username:this.username}).then(res=>{
                if(res.code==1){
                       this.$toast(res.code_dec);
                       this.show=false;
                         this.$toast.clear();
                }else{
                       
                         this.$toast.clear();
                         Dialog.alert({
                            message: res.code_dec, 
                            confirmButtonText:"CLOSE"
                            }).then(() => {
                            // on close
                            });
                }
             })
        },
        doTransfer:function(){

            if(!this.real_money){
                   this.$toast("Please enter the amount you want to withdraw");
                   return;
            }
            if(!this.username){
                   this.$toast("Please enter username");
                   return;
            }
             this.goTransfer();
            
        },
        cacfee:function(){
            if(this.money>0){
                this.fee=this.money*0.01;
                this.real_money=this.money-this.fee;
                this.real_money=this.real_money.toFixed(2);
                this.showActive=true;
            }else{
                this.fee=0;
                this.real_money=0;
            }
        },
        onClickLeft:function(){
            this.$router.back(-1)
        },
        hasSelect:function(index){
            if(this.preSelectIndex!=null){
                 this.amountList[this.preSelectIndex].select=false;
            }
           this.amountList[index].select=true;
           this.preSelectIndex=index;
        },
        getNewBalance(){
            ///获取最新的 用户信息
            post("api/wallet/balance",{}).then(res=>{
              this.balance=res.data;
              console.log("this.balance",this.balance);
            })
        },
         closepop:function(){
             this.show=false;
        },
        createOrder:function(){
            if(this.preSelectIndex==null&&!this.money){
                this.$toast("Please enter the amount to recharge");
                return;
            }
            if(!this.rechargeInfo){
                this.$toast("Payment channel not obtained");
                return;
            }
            if(this.preSelectIndex!=null){
                this.money=this.amountList[this.preSelectIndex].amount;
            }
            var temp={recharge_id:this.rechargeInfo.id,money:this.money,name:''};
            this.$toast.loading({
            duration:0,
            message: 'loading...',
            forbidClick: true,
            loadingType: 'spinner' 
            });
            post("/api/recharge/newRechargeOrder",temp).then(res=>{
                 this.$toast.clear();
                if(res.code_dec=="success"){
                    this.$toast(res.code_dec);
                    console.log(this.src);
                    var self=this;
                    setTimeout(()=>{
                        console.log("延迟执行");
                      if(self.src){
                            
                            self.$router.push({
                                    path: "/"+self.src,
                                });
                        }
                    },1000)
                    
                }else{
                    this.$toast(res.code_dec) 
                }
                
            }).catch(err=>{
                 this.$toast.clear();
            })
        },
       init:async function(){
            this.$toast.loading({
            duration:0,
            message: 'loading...',
            forbidClick: true,
            loadingType: 'spinner' 
            });
            var userInfo=await getUserInfo();
            if(userInfo==null||!userInfo){
                this.$router.push({
                    path: "/login",
                    query:{src:"tranfer"} 
                });
                
                return;
            }
            this.userInfo=userInfo;
           this.$toast.clear();
          

       }
    },
    activated(){
        this.real_money="";
        this.money="";
        this.username="";
        this.amountList=[];
     this.init();
      this.$store.dispatch("setShowNav",false);
     this.src=this.$route.query.src;
     console.log("this.src"+this.src);
    },
    created(){
       this.getNewBalance();
    },
    mounted(){

    }
}
</script>

