<template>
    <div class="business_hall">
         <div class="business_hall_header">
            <van-nav-bar    left-arrow  @click-left="onClickLeft">
                <template #title>
                    <div class="title">RETRIEVE PASSWORD</div>
                </template>
                
            </van-nav-bar>
        </div>
        <div class="buywrap">
              <van-cell-group>
                 <van-field label-width="120px" type="text"  v-model="account" label="Account" placeholder="Account" />
                <van-field label-width="120px" type="password"  v-model="password" label="Password" placeholder="Please enter your password" />
                <van-field label-width="120px" type="password" v-model="password2" label="Confirm password" placeholder="Please confirm the password again" />
                <van-field label-width="120px" type="password" v-model="key" label="KEY" placeholder="Please enter KEY" />
                <van-field
  v-model="code"
  center
  clearable
  placeholder="Enter captcha"
>
  <template #button>
    <img :src="basePath+'/api/user/repcha?code_rand='+rkey"/>
  </template>
</van-field>
             </van-cell-group>
             <div style="padding:10px;margin-top:20px;display:flex"><a @click="submitUpdate" style="background: rgb(0, 114, 255);
    flex-grow: 1;
    padding: 10px;
    text-align: center;
    color: #ffffff;
    border-radius: 24px;
    font-size:0.4rem;
}">Confirm</a></div>

        </div>
    </div>
</template>
<script>
function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
import {fetch,post} from '@/util/api'
export default {
    data(){
        return {
             account:"",
             password:"",
             password2:"",
             key:"",
             code:"",
              basePath:process.env.VUE_APP_BASE_API,
             rkey:generateUUID()
        }
    },
    methods:{
        onClickLeft:function(){
              this.$router.back(-1)
        },
        submitUpdate:function(){
            if(!this.password){
                this.$toast("Please enter your password");
                 return;
            }
            if(!this.password2){

                this.$toast("Please enter your password again");
                 return;
            }
            if(!this.key){

                this.$toast("Please enter your key");
                 return;
            }
            if(this.password!=this.password2){
                 this.$toast("The passwords you entered twice do not match");
                 return;
            }
this.$toast.loading({
            duration:0,
            message: 'submiting...',
            forbidClick: true,
            loadingType: 'spinner' 
            });
            post("api/user/resetPwd",{pwd:this.password,key:this.key,rkey:this.rkey,username:this.account,code:this.code}).then(res=>{
                this.$toast.clear();
                this.$toast(res.code_dec);
                if(res.code==1){
                    setTimeout(()=>{
                         this.$router.push({
                            path: "/login",
                        });
                    },1000)
                }
                 
                 
            })
        }
    },
    activated(){
        this.$store.dispatch("setShowNav",false);
        this.rkey=generateUUID();
    }
}
</script>    
<style>
.buywrap input {
    width: 100%;
    padding: 5px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 6px;
    color: #0a0a0a;
    background: #f6f6f6;
    font-size: 14px;
    caret-color: #3c6fef;
    outline: none;
    -webkit-transition: all .3s;
    transition: all .3s;
}
</style>