<template>
    <div class="business_hall">
         <div class="business_hall_header">
            <van-nav-bar    left-arrow  @click-left="onClickLeft">
                <template #title>
                    <div class="title">buy It Now</div>
                </template>
                
            </van-nav-bar>
        </div>
        <div class="buywrap">
               <van-cell title="【Coinbase】Comprar USDT" is-link url="https://www.coinbase.com"/>
               <van-cell title="【Moonpay】Comprar USDT" is-link url="https://buy.moonpay.com/" />
               <van-cell title="Comprar USDT" is-link url="https://localbitcoins.com/"/>
               <van-cell title="【Huobi OTC】Comprar USDT" is-link url="https://www.huobi.com/zh-cn/fiat-crypto/trade/buy-usdt"/>
               <van-cell title="【Binance】Comprar USDT" is-link url="https://www.binance.com/en"/>
               <van-cell title="【BitoEX】Comprar USDT" is-link url="https://www.bitoex.com/"/>
               <van-cell title="【Shakepay】Comprar USDT" is-link url="https://shakepay.com/"/> 

        </div>
    </div>
</template>
<script>
export default {
    data(){
        return {

        }
    },
    methods:{
        onClickLeft:function(){
              this.$router.back(-1)
        }
    },
    activated(){
        this.$store.dispatch("setShowNav",false);
    }
}
</script>    