<template>

<div class="login">
      <div class="person_header">
            <van-nav-bar    left-arrow  @click-left="onClickLeft">
                <template #title>
                    <div class="title"> </div>
                </template>
                
            </van-nav-bar>
        </div>
        <div>
              <div class="login_word">

                Hello! Welcome to login! 
              </div>
              <div class="login_panel">
                   <div style="margin-bottom:1rem;">
                     <div style="font-size:0.4rem;margin-bottom:5px">Account</div>
                     <div><input type="text" v-model="username" class="input-text" placeholder="Please enter your username or email address"></div>
                    </div>     
                   <div style="margin-bottom:1rem;">
                     <div style="font-size:0.4rem;margin-bottom:5px">Password</div>
                     <div><input type="password" v-model="password" class="input-text" placeholder="Please enter your password"></div>
                    </div>   
                  <div style="margin-bottom:1rem;display:flex">
                     <div class=""><input type="text" v-model="code" class="input-text" placeholder="Enter captcha"/></div>
                     <img @click="changeCode" :src="basePath+'/api/user/repcha?code_rand='+rkey" style="width:120px;margin-left:10px;"/>
                  </div>
                  <div style="margin-bottom:1rem;display:flex">
                     <a class="loginbtn btnactive" @click="doLogin" style="width:100%;padding:0.3rem;flex-grow:1;text-align:center">Login</a>
                  </div>
                  <div>
                     <div style="font-size:0.35rem">No account?<a @click="goRegister" class="txtblue">REGISTER</a></div>
                     <div style="font-size:0.35rem;margin-top:0.5rem;"><a class="txtblue" @click="goReset">RETRIEVE PASSWORD</a></div>

                  </div>
              </div>

        </div>

</div>
 
</template>
<style scoped>
.btnactive{
      background: #588bf8;
    opacity: 0.97;
    cursor:pointer;
}
.txtblue{
  color:#2196f3!important;
}
.loginbtn{
  border-radius:1rem;
  font-size:0.45rem;
  font-weight:bold;
  color:#ffffff;
}
.disableBtn{
  background: #999;
}
 .login_panel input {
    resize: none;
    border: none;
    outline: 0;
    -webkit-box-shadow: none;
    box-shadow: none; 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
.input-text, .input-select {
    width: 100%;
    padding: 10px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 6px;
    color: #0a0a0a;
    background: #f6f6f6;
    font-size: 14px;
    caret-color: #3c6fef;
    outline: none;
    -webkit-transition: all .3s;
    transition: all .3s;
}
.tip{
  margin-top:10px;
}
.tip>a:nth-child(1){
  color:#999999;
}
.tip>a:nth-child(2){
  color:#333333;
}
.loginbtn{
  margin-top:20px;
}
.loginbtn>a{

  height:44px;
  width:92px;
  color:#ffffff;
  background: #F9034F;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size:16px;
  font-weight: bold;
}
.rember{
  display:flex;
  flex-direction: row;
  align-items: center; 
  margin-top:-10px;
}
.input_item{
  border-bottom: 1px solid #E5E5E5;
  padding-bottom:10px;
  margin-bottom: 20px;
}
.inputspec{
 margin-left:20px;
}
.input{
  flex-grow: 1;
  border:1px solid transparent; 
  font-size:14px;

}
.input_item>div:nth-child(1){
  font-size:15px;
  font-weight:bold;
}
.input_item>div:nth-child(2){
   margin-top:5px;
   display: flex;
   flex-direction: row;
}
.input_item>div:nth-child(2)>a{
  width:50px;
  background: #F9034F;
  padding:5px;
  display: flex;
  color:#ffffff;
  border-radius: 2px;
  position: relative;
  text-align: center;
  align-items: center;
  justify-content: center;

}
.input_item>div:nth-child(2)>a::after{
   position: absolute !important;
    top: 50%;
    right: 5px;
    margin-top: -5px;
    border: 3px solid;
    border-color: transparent transparent #ffffff #ffffff;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    opacity: 0.8;
    content: '';
    position: relative;
}
.login_panel{
  margin:24px;
}
.person_header .title{
    font-weight: bold;
    color:#333333;
}
.person_header{
    height:50px;
}
.login_word{
  margin-left:24px;
   margin-top:25px;
   font-size:0.65rem;
}
.login_word img{
  width:137px;
}
.login{
  font-size:12px;
}
</style>
<script>
import {fetch,getUserInfo} from '@/util/api'
function generateUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16 | 0, v = c === 'x' ? r : (r & 0x3 | 0x8);
        return v.toString(16);
    });
}
export default {
    data(){
        return {
            username: '', 
            password: '',
            code:"",
            checked:false,
            rkey:generateUUID(),
            basePath:process.env.VUE_APP_BASE_API,
        }
    },
    methods:{
      goReset:function(){
          
            this.$router.push({
              path: "/reset"  
            });
      },
      changeCode:function(){
        this.rkey=generateUUID();
      },
      checkLogin:async function(){
          var userInfo=await getUserInfo();
          if(userInfo!=null&&userInfo){
            console.log("检查是否存在用户信息",userInfo);
            this.$router.push({
              path: "/"  
            });
          }
      },
      onClickLeft:function(){
         this.$router.back(-1)
      },
      goRegister:function(){
        this.$router.push({
              path: "/register"  
        });
      },
      doLogin() { 
      if(!this.username){
        this.$toast("Please enter your user name");
        return;
      }
 
      if(!this.password){
        this.$toast("Please enter the login password");
        return;
      }
        this.$toast.loading({
            duration:0,
            message: 'logining...',
            forbidClick: true,
            loadingType: 'spinner' 
        });
        fetch("api/user/login",{user_name:this.username,pwd:this.password,rkey:this.rkey,code:this.code,lang:'en'}).then(res=>{
            console.log("res",res);
            if(res.code==1){
              if(this.checked){
                window.localStorage["user"]=this.username;
                 window.localStorage["pwd"]=this.password;
              }else{
                window.localStorage["user"]="";
                window.localStorage["pwd"]="";
              }
              this.$store.dispatch('setToken',res.info);
              this.$store.dispatch("loginActive",false);
              console.log("getter",this.$store.getters.userInfo);
              console.log("登录成功，回跳地址:"+this.src);
              this.$toast.clear();
              if(!this.src||this.src=="invite"){
              this.$router.push({
                      path: "/",
                  });
              }else{
              this.$router.push({
                      path: "/"+this.src,
                  });
              }
            }else{
              this.$toast(res.code_dec);
              this.changeCode();
            }


        }).catch(err=>{
           this.$toast.clear();
        })
      },
    },
    activated(){
      if(window.localStorage["user"]&&window.localStorage["pwd"]){
                this.username=window.localStorage["user"];
                this.password=window.localStorage["pwd"];
                this.checked=true;
                
      }
      this.rkey=generateUUID();
      this.username="";
      this.password="";
      this.code="";
       this.$store.dispatch("setShowNav",false);
      this.src=this.$route.query.src;

      //判断如果是已经登录状态，则直接
      ///如果是登录状态 则直接跳转到首页
       this.checkLogin();


    },
    mounted(){
      console.log("getter",this.$store.getters.userInfo);
     
    }
}
</script>

