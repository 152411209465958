<template>
    <div class="recharge">
          <div class="recharge_header">
              <van-nav-bar    left-arrow  @click-left="onClickLeft">
                <template #title>
                    <div class="title">Help center</div>
                </template>
                 
            </van-nav-bar>

          </div>
          <div class="content" style="padding-bottom:100px">
                <van-collapse v-model="activeName" accordion>
                <van-collapse-item title="1. Platform usage instructions" name="1"><p>The USDT team officially enters the digital asset trading service platform.<br>With reliable and scalable technical design, high-quality services and more support, the USDT digital asset trading platform aims to provide its special users with more convenient, safer and more convenient USDT exchange transaction services and high-quality blockchain assets.Global VIP customers provide deposits, transfers, withdrawals, financial management and investment services.VIP exclusive financial management, investment. 24-hour customer service, 1-to-1 service..<br>
1. The platform is open for registration. Anyone can register their own account<br>
2. The VIP level of the newly registered account is 0 (recharge, transfer, cash withdrawal,). These are free and you don't need to upgrade your account to operate them.If your account needs to receive transfers from others, you need to use payment limits (at this time, level 0 accounts will not be able to receive payments), and you need to upgrade your account level to VIP to use the payment function; the higher the level, everyone’s daily and monthly allowances will be If different, please upgrade as needed.<br>
3. We can also provide various services such as currency exchange and USDT mortgage loans for VIP customers<br>
</p></van-collapse-item>
                <van-collapse-item title="2. How to activate VIP?" name="2"><p>1. Click the VIP button<br>
2. Select the VIP level you want to purchase<br>
3. Pay the VIP deposit and purchase successfully<br>
4. Deposit required for VIP level (you can cancel and return your deposit at any time)<br>
VIP1 deposit: 50 USDT (VIP1 is a novice experience service, valid for 2 months, the deposit cannot be refunded and cannot be canceled)<br>
VIP2 Deposit: 1000 USDT<br>
VIP3 Deposit: 3500 USDT<br>
VIP4 Deposit: 30000 USDT<br>
VIP5 Deposit: 50000 USDT<br>

The VIP level of the newly registered account is 0 (recharge, transfer, withdrawal). These are free and you don't need to upgrade your account to operate them. If your account needs to receive transfers from others, you need to use payment limits (at this time, level 0 accounts will not be able to receive payments), and you need to upgrade your account level to VIP to use the payment function; the higher the level, everyone’s daily and monthly allowances will be If different, please upgrade as needed.<br>
VIP1: Payment limit: 10 USDT per day, 30 USDT per month<br>
VIP2: Payment limit: 100 USDT per day, 3,000 USDT per month<br>
VIP3: Payment limit: 25,000 USDT per day, 200,000 USDT per month<br>
VIP4: Payment limit: 30,000 USDT per day, 900,000 USDT per month<br>
VIP5: Payment limit: 100,000 USDT per day, 3,000,000 USDT per month<br>

</p></van-collapse-item>
                <van-collapse-item title="3.VIP transfer (no KEY required)" name="3">
<p>
                    Note: VIPs can use the platform transfer function. Transfers can only be made between platform VIPs (the transfer cannot be returned if successful)<br>

                 1. Click the transfer button<br>
                 2. Correctly filled in VIP username, email address and mobile phone number<br>
                 3. Click Confirm to complete the transfer (no need to enter KEY)<br>
                 4. If your account needs to receive transfers from others, you need to use payment limits<br>
                      VIP1: Payment limit: 10 USDT per day, 30 USDT per month<br>
                      VIP2: Payment limit: 100 USDT per day, 3,000 USDT per month<br>
                      VIP3: Payment limit: 25,000 USDT per day, 200,000 USDT per month<br>
                      VIP4: Payment limit: 30,000 USDT per day, 900,000 USDT per month<br>
                      VIP5: Payment limit: 100,000 USDT per day, 3,000,000 USDT per month<br>
					  
					  Transfer limit example: If your account is VIP5<br>
Transfer VIP5 to VIP1 (10USDT)<br>
Transfer VIP5 to VIP2 (100USDT)<br>
Transfer VIP5 to VIP3 (25000USDT)<br>
Transfer VIP5 to VIP4 (30000USDT)<br>
VIP5 transfer to VIP5 (100000USDT)<br>
                 </p>

                </van-collapse-item>
                 <van-collapse-item title="4.How to recharge?" name="4">
<p>1. Click the recharge button<br>
2. Select the amount to be recharged<br>
3. Click Confirm. Enter (KEY)<br>
4. You will get an exclusive deposit address for your account, which you can transfer from any exchange or wallet.<br>
5. The amount you recharge will be automatically added to your account balance<br>
</p>

                 </van-collapse-item>
                <van-collapse-item title="5.How to withdraw cash?" name="5">
<p>
1. Click the Withdraw button<br>
2. Enter the USDT withdrawal address (TRC20)<br>
3. Enter the withdrawal amount<br>
4. Click the Confirm button and enter KEY<br>
5. The withdrawal is successful and your withdrawal will arrive within 2 hours.<br>


</p>

                </van-collapse-item>
                 <van-collapse-item title="6. What should I do if I forget my KEY?" name="6">
<p>1. The KEY is set by yourself and cannot be obtained by others. And the KEY is unique and cannot be reset,<br>
2.KEY is the only proof of your account. It cannot be modified. Unable to retrieve<br>
3. If you forget the KEY. Your account still has funds to withdraw. You can re-register a new account and upgrade VIP, and then transfer funds from your old account to your new account for withdrawal. Transfers within the VIP platform do not require a KEY<br>


KEY is the only credential for your account and cannot be modified or retrieved. In order to protect customer privacy, the platform will not record the user's account number, password, mobile phone number, email, or KEY. Please keep your login information safe

</p>


                 </van-collapse-item>
                 <van-collapse-item title="7. How to change password, email?" name="7">
<p>1. Click on my homepage<br>
2. Click the Security Center button<br>
3. Select the password or email that needs to be changed<br>
4. Enter KEY to complete<br>
</p>

                 </van-collapse-item>
                 <van-collapse-item title="8. How to register an account?" name="8">
<p>Note: When registering, please do not open the translation software. If you open the translation software, the button cannot be confirmed. If you cannot register, please change the browser)<br>
1. Click the registration button on the homepage<br>
2. Click to register<br>
3. Fill in your registration information<br>
4. Please keep it properly when setting the KEY. It cannot be modified or retrieved (loss may cause property damage)<br>
</p>

                 </van-collapse-item>
                 <van-collapse-item title="9. How to invest?" name="9">
<p>Store safely and earn easily<br>

1. Minimum storage amount: 5,000 USDT (account funds below 5,000 cannot enter financial services)<br>
2: Your assets will receive income according to different levels, and the total income at each level is added up.<br>
3: The expected annualized income will be adjusted at any time based on the actual income of the mining pool.<br>
4: The income will be calculated one day after PoS is opened.<br>
5: No need to freeze mortgage assets, you can trade or withdraw coins at any time.<br>
Represents the pledge date. Earnings will begin on T+1 (the next day) and will be distributed to your account on T+2. No income will be generated on the day you release your pledged assets. Earnings will be distributed to your spot account on a daily basis.
Current financial management can be canceled at any time, and the income will be settled until the day before exiting PoS. For other products, when the minimum storage period is reached, you can choose to redeem or continue to store. The final income will be calculated based on the actual number of storage days, and the principal will be unlocked on the redemption unlocking day.
Among the financial products provided, you can choose from dozens of digital assets (such as Bitcoin, Ethereum), and stable coins.<br>


</p>

                 </van-collapse-item>
                 <van-collapse-item title="10.VIP investment services" name="10">

                    <p>1. Thank you again for becoming our member, we will wholeheartedly provide you with better service and support!<br>
2. You will receive transfer and collection services<br>
3. You will enjoy exclusive VIP rates and more favorable transaction costs.<br>
4. You will receive a VIP privileged badge to show your identity and status.<br>
5. You will join our VIP exclusive service group and receive more exclusive customer support.<br>
6. You will receive regular industry reports to learn about market dynamics and investment opportunities.<br>
7. You will enjoy exclusive holiday gifts and customized souvenir privileges, allowing you to feel our care.<br>
8. You will have the opportunity to participate in exclusive VIP activities and meet more successful investors.<br>
9. You will enjoy an exclusive 24-hour VIP customer service channel and receive faster and priority solution support.<br>
10. You will get an independent green channel and enjoy more convenient deposit and withdrawal services.<br>
</p>
                 </van-collapse-item>
                 <van-collapse-item title="11.VIP cancellation (deposit refund)" name="11">
                    <p>If you don't want to use VIP, you can cancel at any time. VIP2-VIP5 users can refund their deposit at any time<br>
                1. Click the VIP button<br>
2. Click on the VIP that needs to be canceled<br>
3. Enter KEY. If the cancellation is successful, the funds will be returned to your account.<br>
</p></van-collapse-item>
                </van-collapse>

          </div>
    </div>
</template>
<script>
export default {
    data(){
        return{
           activeName:'0'
        }
    },
    methods:{
        onClickLeft:function(){
                this.$router.back(-1)
        }
    },
    activated(){
         
      this.$store.dispatch("setShowNav",false); 
    },

}
</script>